const latitude = document.getElementById('latitude')
const longitude = document.getElementById('longitude')
const altitude = document.getElementById('altitude')
const accuracy = document.getElementById('accuracy')
const time = document.getElementById('time')
const altitudeAccuracy = document.getElementById('altitude-accuracy')
const speed = document.getElementById('speed')
const heading = document.getElementById('heading')
const googleMapsLink = document.getElementById('google-maps')

navigator.geolocation.watchPosition(setPosition, handlePositionError)

function setPosition(position) {
  console.log(position);
  setUiField(latitude, position.coords.latitude);
  setUiField(longitude, position.coords.longitude);
  setUiField(accuracy, position.coords.accuracy);
  setUiField(altitude, position.coords.altitude);
  setUiField(altitudeAccuracy, position.coords.altitudeAccuracy);
  setUiField(speed, position.coords.speed);
  setUiField(heading, position.coords.heading);
  setUiField(time, new Date(position.timestamp).toLocaleTimeString());
  setGoogleMapLink(position)
}

function handlePositionError(error) {
  console.log(error);
}

function setUiField(field, value) {
  field.innerText = value || 'Unknown';
}

function setGoogleMapLink(position) {
  googleMapsLink.href = `http://maps.google.com/maps?q=${position.coords.latitude},${position.coords.longitude}`
  googleMapsLink.hidden = false;
}
